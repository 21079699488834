<template>
  <b-sidebar id="add-new-sidebar" :visible="isAddNewSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow
    backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- Nome Abreviado -->

          <validation-provider #default="validationContext" name="Nome Abreviado">
            <b-form-group label="Nome Abreviado" label-for="nome-produto">
              <b-form-input v-model="itemData.nome" name="nome-produto" required autofocus
                :state="getValidationState(validationContext)" trim placeholder="Informe o nome do produto" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Descrição -->
          <b-form-group label="Descrição" label-for="nome-produto">
            <b-form-textarea id="textarea-default" placeholder="Descrição do produto" rows="3"
              v-model="itemData.descricao" />
          </b-form-group>

          <!-- Marca -->
          <validation-provider #default="validationContext" name="Marca">
            <b-form-group label="Marca" label-for="marca">

              <v-select v-model="itemData.marca" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="marcaOptions" label="nome" :reduce="val => val.id" :clearable="false" input-id="marca"
                placeholder="Escolha a marca">
                <template v-slot:no-options>
                  Nenhuma opção encontrada
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
             <!-- Marca -->
          <validation-provider #default="validationContext" name="Família">
            <b-form-group label="Família" label-for="familia">

              <v-select v-model="itemData.familia" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="familiaProdutoOptions" label="nome" :reduce="val => val.id" :clearable="false" input-id="familia"
                placeholder="Escolha a família">
                <template v-slot:no-options>
                  Nenhuma opção encontrada
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Quantidade -->
          <validation-provider #default="validationContext" name="Quantidade">
            <b-form-group label="Quantidade" label-for="Quantidade">
              <b-form-input id="quantidade" v-model="itemData.quantidade" :state="getValidationState(validationContext)"
                trim required type="number" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider #default="validationContext" name="Unidade Medida">
            <b-form-group label="Unidade de Medida" label-for="unidade-medida"
              :state="getValidationState(validationContext)">

              <v-select v-model="itemData.unidade_medida" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="unidadeMedidaOptions" label="abreviacao" :reduce="val => val.uuid" :clearable="false"
                input-id="unidade-medida" placeholder="Escolha a unidade de medida">
                <template v-slot:no-options>
                  Nenhuma opção encontrada
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Observação -->
          <validation-provider #default="validationContext" name="Observação">
            <b-form-group label="Observação" label-for="Observação">
              <b-form-input id="cep" ref="Observação" v-model="itemData.observacao"
                :state="getValidationState(validationContext)" trim placeholder="Insira uma observação" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="fotos">
            <b-form-group label="Fotos" label-for="fotos">
              <b-form-file v-model="itemData.fotos" label="Fotos" label-for="fotos"
                :state="getValidationState(validationContext)" browse-text="Procurar" placeholder="Escolha um arquivo"
                drop-placeholder="Escolha um arquivo" multiple />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-2" type="submit">
              Salvar
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { alphaNum, email, required } from '@validations'
  import {
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadio,
    BSidebar,
    BFormTextarea,
    
  } from 'bootstrap-vue'
  import { ref } from '@vue/composition-api'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store'
  import vSelect from 'vue-select'

  // Notification
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormFile,
      vSelect,
      BFormRadio,
        BFormTextarea,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewSidebarActive',
      event: 'update:is-add-new-sidebar-active',
    },
    props: {
      isAddNewSidebarActive: {
        type: Boolean,
        required: true,
      },
      titulo: {
        type: String,
        required: true,
      },
      unidadeMedidaOptions: {
        type: Array,
        required: true,
      },
      marcaOptions: {
        type: Array,
        required: true,
      },
      familiaProdutoOptions: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,

      }
    },
    setup(props, { emit }) {
      const blankitemData = {
        nome: '',
        marca: null,
        quantidade: 1,
        unidade_medida: null,
        observacao: '',
      }
      const isAddNewSidebarActive = ref(false)
      // Use toast
      const toast = useToast()

      const itemData = ref(JSON.parse(JSON.stringify(blankitemData)))
      const resetitemData = () => {
        itemData.value = JSON.parse(JSON.stringify(blankitemData))
      }

      const onSubmit = () => {
        store.dispatch('produtos/store', itemData.value)
          .then(respo => {
            emit('refetch-data')
            emit('update:is-add-new-sidebar-active', false)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Produto adicionado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })

      }

      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetitemData)

      return {
        itemData,
        onSubmit,
        refFormObserver,
        getValidationState,
        resetForm,
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
