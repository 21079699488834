<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isFiltersSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-filters-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <b-col cols="12" class="mb-2 px-0">
            <b-form-group label="Família" label-for="familia">
              <select-com-pesquisa
                v-if="isFiltersSidebarActive"
                name="familia"
                :itens-para-edicao="[filters.familia]"
                placeholder="Escolha uma família"
                url="/produto-familia?perPage=500"
                :multiple="false"
                :disabled="false"
                @selecionados="tratarEventoFamiliaSelecionado"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2 px-0">
            <b-form-group label="Marca" label-for="Marca">
              <select-com-pesquisa
                v-if="isFiltersSidebarActive"
                name="Marca"
                :itens-para-edicao="[filters.marca]"
                placeholder="Escolha uma marca"
                url="/marcas"
                :multiple="false"
                :disabled="false"
                @selecionados="tratarEventoMarcaSelecionado"
              />
            </b-form-group>
          </b-col>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              Aplicar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BInputGroup,
  BButton,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BSidebar,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required, valorFinalMaiorInicial } from '@validations'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import { limpaMoeda } from '@core/utils/filter'
export default {
  components: {
    BRow,
    BInputGroup,
    SelectComPesquisa,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isFiltersSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filters: {},
    }
  },
  watch: {
    isFiltersSidebarActive() {
      this.filters = this.$store.getters['produtos/getFilters']
    },
  },
  methods: {
    async onSubmit() {
      const payload = {
        familias: this.filters.familia ? [this.filters.familia.id] : null,
        marcas: this.filters.marca ? [this.filters.marca.id] : null,
      }

      this.$store.commit('produtos/setFilters', this.filters)
      this.$emit('aplicar-filtros', payload)
      this.$emit('update:is-filters-sidebar-active', false)
    },
    tratarEventoFamiliaSelecionado(familia) {
      this.filters.familia = familia
    },
    tratarEventoMarcaSelecionado(marca) {
      this.filters.marca = marca
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
