import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { usePagination } from '@/commons/hooks/pagination/use-pagination'
import { HandlerVariant } from '@/commons/utils/erros/variant'
import { Icons } from '@/commons/utils/literals/icons'
import { Literals } from '@/commons/utils/literals/literals'
import { PaginationStateKeys } from '@/commons/stores/pagination/pagination'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refItemListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: 'Cód.',
      sortable: true,
    },
    { key: 'marca', label: 'Nome/Marca', thStyle: { width: '30%' } },
    {
      key: 'descricao',
      label: 'Descrição',
      thStyle: { width: '20%' },
    },
    {
      key: 'familia.abreviacao',
      label: 'Família',
    },

    {
      key: 'quantidade',
      label: 'Qtd.',
    },
    {
      key: 'unidade_medida',
      label: 'U.M.',
    },

    { key: 'fotos' },
    {
      key: 'Ações',
      class: 'text-center',
    },
  ]

  const filters = ref({})

  const getFilters = () => {
    Object.keys(filters.value).forEach((key) => {
      if (!filters.value[key]) {
        delete filters.value[key]
      }
    })
  }

  const {
    perPage,
    totalItens,
    currentPage,
    perPageOptions,
    searchQuery,
    isSortDirDesc,
    sortBy,
  } = usePagination({
    id: PaginationStateKeys.GRID_CLIENTES,
    sortBy: 'created_at',
    isSortDirDesc: true,
  })

  const dataMeta = computed(() => {
    currentPage.value - 1

    const localItemsCount = refItemListTable.value
      ? refItemListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refItemListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    getFilters()
    store
      .dispatch('produtos/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        ...filters.value,
      })
      .then((response) => {
        callback(response.data)
        totalItens.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: Literals.FETCH_GRID_PRODUTOS_ERROR,
            icon: Icons.ALERT_TRIANGLE_ICON,
            variant: HandlerVariant.DANGER,
          },
        })
      })
  }

  const deleteItem = (id) => {
    store.dispatch('produtos/deleteItem', id).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Produto excluído com sucesso',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      refetchData()
    })
  }

  return {
    fetchDados,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refItemListTable,
    refetchData,
    filters,
  }
}
