export class Literals {
  // literal usada quando não for possivel carregar os usuarios da grid.
  static FETCH_GRID_USUARIOS_ERROR = 'Erro ao listar os usuários'
  // literal usada quando não for possivel carregar os clientes da grid.
  static FETCH_GRID_CLIENTES_ERROR = 'Erro ao listar os clientes'
  // literal usada quando não for possivel carregar os produtos da grid.
  static FETCH_GRID_PRODUTOS_ERROR = 'Erro ao listar os produtos'
  // literal usada quando não for possivel carregar as obras da grid.
  static FETCH_GRID_OBRAS_ERROR = 'Erro ao listar as obras'
  // literal usada quando não for possivel carregar os projetos da grid.
  static FETCH_GRID_PROJETOS_ERROR = 'Erro ao listar os projetos'
  // literal usada quando não for possivel carregar as propostas da grid.
  static FETCH_GRID_PROPOSTAS_ERROR = 'Erro ao listar as propostas'
}
